<template>
  <!--  TODO://cash-point 페이지와 비슷하게 작업해 두었습니다.-->
  <main class="main" role="main">
    <div class="page bg">
      <div class="inner">
        <div class="page_con">
          <div class="page_tab">
            <div class="btn_wrap">
              <button
                :class="{ on: buttonIndex === 0 }"
                @click="() => (buttonIndex = 0)"
              >
                구매
              </button>
              <button
                :class="{ on: buttonIndex === 1 }"
                @click="() => (buttonIndex = 1)"
              >
                판매</button
              ><!--'on'-->
            </div>
          </div>
          <SearchConditions
            :conditionOpen="conOpen"
            :height="'h115'"
            :conditionsList="conditionsList"
            @update="fnUpdateConditionsList($event)"
            @updateOnOff="fnUpdateOnOffConditions($event)"
          />
          <div class="lump bo_zero" style="border: 0;">
            <label class="input srh_type02">
              <input
                type="search"
                placeholder="검색어를 입력해 주세요."
                :value="searchWord"
                @input="inputSearchWord"
                @keyup.enter="fnSearch"
              />
              <button
                v-if="searchWord"
                class="btn_search delete"
                @click="() => (searchWord = '')"
              ></button>
              <button class="btn_search" @click="fnSearch">검색</button>
            </label>
          </div>
          <div class="party_list_wrap">
            <div class="list_top">
              <div class="left">
                <button class="total">총 {{ totalCnt }}건</button>
              </div>
              <div class="right">
                <!-- 211223 추가 -->
                <button
                  class="li_btn up"
                  :class="{ on: order === 'ASC' }"
                  @click="() => (order = 'ASC')"
                ></button>
                <button
                  class="li_btn down"
                  :class="{ on: order === 'DESC' }"
                  @click="() => (order = 'DESC')"
                ></button>
                <!-- 211223 추가 끝 -->
                <label v-if="buttonIndex == 1" class="select">
                  <select v-model="sort">
                    <option :value="'MODDT'">업데이트순</option>
                    <option :value="'REGDT'">등록순</option>
                    <option :value="'PRICE'">판매가순</option>
                    <option :value="'DC'">할인순</option>
                  </select>
                </label>
                <label v-else class="select">
                  <select v-model="sort">
                    <option :value="'PAY'">결제순</option>
                    <option :value="'PERIOD'">유효기간순</option>
                    <option :value="'PRICE'">금액순</option>
                  </select>
                </label>
              </div>
            </div>
            <!-- 210810 수정-->
            <market-list
              v-if="!$Util.isEmpty(list)"
              :items="list"
              :tab-index="buttonIndex"
              @fetch="fnSearch"
            />
            <div class="empty_wrap" v-else>
              <div class="empty">
                <div>마켓 검색 결과가 없습니다.</div>
              </div>
            </div>

            <div class="page_btn" v-if="buttonIndex == 1">
              <div class="btn_wrap">
                <button class="btn" @click="fnMarketWrite">
                  마켓 등록하기</button
                ><!--버튼 중복: 웹 분기점 디자인 적용-->
              </div>
            </div>

            <observe-visible
              :key="initCnt"
              :page="page"
              :limit="limit"
              :totalCnt="totalCnt"
              @fetch="fnListFunction"
            />
            <!-- 210810 수정 끝-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MarketList from '@/components/views/my-page/market/list/index.vue'
import SearchConditions from '@/components/views/my-page/SearchConditions'

import { componentState } from './index'
export default {
  name: 'my-page-market-index',
  components: { MarketList, SearchConditions },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
