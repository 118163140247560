<template>
  <div class="party_list row_type">
    <!--'가로형: row_type'-->
    <ul class="party_list_ul">
      <li
        class="party_list_li type_02 market"
        :class="{
          disabled:
            item.statusNm === '사용완료' ||
            (tabIndex == 1 && item.statusNm === '거래완료') ||
            item.statusNm === '자체마감' ||
            item.statusNm === '환불완료'
        }"
        v-for="(item, index) in items"
        :key="index"
      >
        <!--type02추가-->
        <div class="area" @click="fnView(item)">
          <div class="party_tag">
            <div class="left">
              <div
                class="pt_tag logo"
                style="--pt-bg-color: #003471;--pt-txt-color: #fff;"
              >
                {{ item.categoryNm }}
              </div>
              <div class="pt_tag normal nb">
                {{ tabIndex == 0 ? item.quantity : item.remainQuantity }}
              </div>
              <div
                class="pt_tag"
                :class="{ new: Number(item.hasBadge) === 1 }"
              ></div>
            </div>
            <div class="right">
              <div class="btn_wrap"></div>
            </div>
          </div>
          <div class="party_ttl">
            {{ item.title }}
          </div>
          <div class="party_con">
            <div class="left">
              <div class="party_info">
                <dl>
                  <dt>상태 :</dt>
                  <dd>{{ item.statusNm }}</dd>
                </dl>
              </div>
            </div>
            <div class="right">
              <div class="party_price">
                <span
                  class="value"
                  :class="{ end: item.statusNm === '사용완료' }"
                  >{{ $Util.formatNumber2(item.price, { unit: '' }) }}</span
                ><!--띄어쓰기-->
                <span class="unit">원</span>
              </div>
            </div>
          </div>
        </div>
        <div class="line_gr"></div>
        <div class="party_btm" v-if="tabIndex === 1">
          <div class="color_primary" @click="fnWizztalk(item)">
            위즈톡 보기
            <span class="color_error" v-if="item.wizztalkBadge === '1'">N</span>
          </div>
          <div class="right">
            <button
              class="ext_rd"
              v-if="item.statusNm === '판매중'"
              @click="fnMarketClose(item)"
            >
              판매 중지
            </button>
            <button class="ext_bl" @click="fnReRegister(item)" v-else>
              재등록
            </button>
          </div>
        </div>
        <div class="party_btm" v-if="tabIndex === 0">
          <div class="color_primary" @click="fnWizztalk(item)">
            위즈톡 보기
            <span class="color_error" v-if="item.wizztalkBadge === '1'">N</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { componentState } from './index.js'
export default {
  name: 'component-my-page-market-list-index',
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },
    tabIndex: {
      type: [Object, Number],
      default: 0
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
