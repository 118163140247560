import { computed, reactive, toRefs, getCurrentInstance, watch } from 'vue'
import { CommonFunction } from '@/common/CommonFunction'
import { useRoute, useRouter } from 'vue-router'

const INIT_PAGE = 0
const INIT_LIMIT = 6
const INIT_TOTAL_CNT = 0

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const status = (route.query.status && JSON.parse(route.query.status)) || []
  const buttonIndex = Number(route.query.index) || 0
  const getConditionList = () => {
    if (buttonIndex === 0) {
      if (proxy.$Util.isEmpty(status)) {
        return proxy.$ConstCode.BUY_MARKET_CONDITION.map(c => ({
          ...c,
          value: false
        }))
      } else {
        return proxy.$ConstCode.BUY_MARKET_CONDITION.map(c => {
          return {
            ...c,
            value: status.find(s => s === c.status) ? true : false
          }
        })
      }
    } else {
      if (proxy.$Util.isEmpty(status)) {
        return proxy.$ConstCode.SALE_MARKET_CONDITION.map(c => ({
          ...c,
          value: false
        }))
      } else {
        return proxy.$ConstCode.SALE_MARKET_CONDITION.map(c => {
          return {
            ...c,
            value: status.find(s => s === c.status) ? true : false
          }
        })
      }
    }
  }
  const state = reactive({
    cash: 0,
    page: INIT_PAGE,
    limit: INIT_LIMIT,
    totalCnt: INIT_TOTAL_CNT,
    totalPoint: 0,
    list: [],
    initCnt: 0,
    sort: route.query.sort || (buttonIndex == 0 ? 'PAY' : 'MODDT'),
    order: route.query.order || 'DESC',
    searchWord: route.query.search || '',
    conOpen: (route.query.open && JSON.parse(route.query.open)) || false,
    status,
    conditionsList: getConditionList(),
    params: computed({
      get: () => {
        return {
          startNum: state.list.length + 1,
          endNum: (state.page + 1) * state.limit,
          searchWord: state.searchWord,
          sort: state.sort,
          status: state.status,
          order: state.order
        }
      }
    }),
    paramsCash: computed({
      get: () => {
        return {
          page: state.page + 1,
          search: state.searchWord
        }
      }
    }),
    buttonIndex
  })
  const fnReset = () => {
    state.page = INIT_PAGE
    state.limit = INIT_LIMIT
    state.totalCnt = INIT_TOTAL_CNT
    state.list = []
  }
  const { fnMarketWrite } = CommonFunction(state)

  const fnListFunction = async () => {
    if (state.buttonIndex === 1) {
      // 판매 목록
      const res = await proxy.$MarketSvc.postMyPageSaleMarketList(state.params)
      if (res.resultCode === '0000') {
        state.page++
        state.totalCnt = res.totalCnt
        state.list = state.list.concat(res.list)
      } else if (res.resultCode === '0001') {
        // 데이터 없는경우
        fnReset()
      } else {
        alert(res.resultMsg)
      }
    } else if (state.buttonIndex === 0) {
      const res = await proxy.$MarketSvc.postMyPageBuyMarketList(state.params)
      if (res.resultCode === '0000') {
        state.page++
        state.totalCnt = res.totalCnt
        state.list = state.list.concat(res.list)
      } else if (res.resultCode === '0001') {
        // 데이터 없는경우
        state.totalCnt = 0
        fnReset()
      } else {
        alert(res.resultMsg)
      }
    }
  }

  const fnUpdateConditionsList = conditionsList => {
    state.conditionsList = conditionsList
    state.status = state.conditionsList.reduce(
      (pv, cv) => (cv.value && cv.status ? [...pv, cv.status] : [...pv]),
      []
    )
  }

  const fnUpdateOnOffConditions = open => {
    state.conOpen = open
  }

  const fnSearch = () => {
    route.query.open = state.conOpen
    const r = proxy.$Util.getRandomString()
    fnReset()
    state.initCnt++
    router.replace({
      name: 'my-page-market-index',
      query: { ...route.query, search: state.searchWord, r }
    })
  }

  const inputSearchWord = e => {
    state.searchWord = e.target.value
  }

  /** watch **/
  watch(
    () => state.buttonIndex,
    () => {
      route.query.index = state.buttonIndex
      route.query.search = ''
      route.query.status = JSON.stringify([])
      state.sort = state.buttonIndex == 0 ? 'PAY' : 'MODDT'
      state.order = 'DESC'
      state.searchWord = ''
      state.initCnt++
      fnSearch()
    }
  )
  watch(
    () => state.sort,
    () => {
      route.query.sort = state.sort
      fnSearch()
    }
  )
  watch(
    () => state.order,
    () => {
      route.query.order = state.order
      fnSearch()
    }
  )
  watch(
    () => state.status,
    () => {
      route.query.status = JSON.stringify(state.status)
      fnSearch()
    }
  )

  return {
    ...toRefs(state),
    fnListFunction,
    fnSearch,
    inputSearchWord,
    fnMarketWrite,
    fnUpdateConditionsList,
    fnUpdateOnOffConditions
  }
}
