import { reactive, toRefs, getCurrentInstance } from 'vue'
import { fnModalAlert } from '@/components/common/modal/alert'
import { useRouter } from 'vue-router'

export const componentState = props => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    tabIndex: props.tabIndex
  })

  const fnCallBackDone = () => {
    proxy.$emit('fetch')
  }

  const fnReRegister = async item => {
    const payload = {
      component: proxy.$modalAlertNames.MARKET_RE_REGIST,
      data: item,
      callback: fnCallBackDone
    }
    await fnModalAlert(payload)
  }
  const fnView = async ({ marketNo, memberSeq }) => {
    if (state.tabIndex === 1) {
      await router.push({
        name: 'my-page-market-view-index',
        query: {
          marketNo,
          memberSeq,
          tabIndex: state.tabIndex
        }
      })
    } else if (state.tabIndex === 0) {
      await router.push({
        name: 'my-page-market-buyer-view-index',
        query: {
          marketNo,
          memberSeq,
          tabIndex: state.tabIndex
        }
      })
    }
  }
  const fnMarketClose = async ({ marketNo }) => {
    if (
      !confirm('판매중지할 경우 물품을 재등록 하셔야됩니다. 중지 하시겠습니까?')
    )
      return
    const res = await proxy.$MarketSvc.postMyPageSaleMarketClose({
      marketNo
    })
    if (res.resultCode === '0000') {
      alert('판매중지되었습니다.')
      proxy.$emit('fetch')
    } else {
      alert(res.resultMsg)
    }
  }

  const fnWizztalk = async ({ marketNo, memberSeq }) => {
    if (state.tabIndex === 1) {
      await router.push({
        name: 'my-page-wizz-talk-index',
        query: { marketNo: marketNo }
      })
    } else {
      const params = {}
      params.marketNo = marketNo
      params.memberSeq = memberSeq
      const res = await proxy.$ChatSvc.postGetMarketChatroomId(params)
      if (res.resultCode === '0000') {
        params.chatroomId = res.chatroomId
        await router.push({
          path: '/market/wizz-talk',
          query: params
        })
      } else {
        alert(res.resultMsg)
      }
    }
  }

  const fnMarketPaymentApproval = async ({ marketNo, memberSeq }) => {
    if (
      !confirm(
        '결제 승인시에는 취소/환불이 불가능합니다.\n' +
          '정말로 결제 승인 하시겠습니까?'
      )
    )
      return
    const params = {}
    params.marketNo = marketNo
    params.memberSeq = memberSeq
    const res = await proxy.$CashSvc.postMarketPaymentApproval(params)
    if (res.resultCode === '0000') {
      alert('결제 승인이 완료 되었습니다.')
      proxy.$emit('fetch')
    } else {
      alert(res.resultMsg)
    }
  }

  return {
    ...toRefs(state),
    fnReRegister,
    fnView,
    fnMarketClose,
    fnWizztalk,
    fnMarketPaymentApproval
  }
}
