<template>
  <div>
    <div
      v-if="(!open && !$Util.isEmpty(tagList)) || (!open && etc)"
      class="lump bo_zero condition_list"
    >
      <div class="tag" v-for="(tag, i) in tagList" :key="i">
        {{ tag.name }}
      </div>
      <div v-if="type === 'PARTY' && etc === 'Y'" class="tag">연장파티</div>
      <div v-if="type === 'PARTY' && etc === 'N'" class="tag">일반파티</div>
    </div>
    <div class="take_part margin">
      <div class="inner">
        <div class="take_part_list condition">
          <div class="take_part_sec mt0">
            <button class="take_part_btn" @click="onOff">
              <span class="buyer_info">
                <span class="font_size16">조건 검색</span>
              </span>
              <span class="off">
                <img v-if="!open" src="@/assets/static/images/ico_down.svg" />
                <img v-else src="@/assets/static/images/ico_up.svg" />
              </span>
            </button>
            <div
              class="buyer_info_detail"
              :class="{ on: open, [height]: height }"
            >
              <div class="inner">
                <div class="form">
                  <ul class="form_ul">
                    <li v-if="windowWidth < 1025" class="form_li">
                      <div class="form_ttl">상태</div>
                      <div class="form_in">
                        <div class="inline">
                          <div
                            class="option_btn"
                            :class="{ on: all }"
                            @click="allSwitch"
                          >
                            <button>전체</button>
                          </div>
                          <div
                            v-for="(con, i) in conditions.slice(0, 2)"
                            class="option_btn"
                            :class="{ on: con.value }"
                            @click="conSwitch(con)"
                            :key="i"
                          >
                            <button>{{ con.name }}</button>
                          </div>
                        </div>
                        <div v-if="conditions.length > 2" class="inline">
                          <div
                            v-for="(con, i) in conditions.slice(2)"
                            class="option_btn"
                            :class="{ on: con.value }"
                            @click="conSwitch(con)"
                            :key="i"
                          >
                            <button>{{ con.name }}</button>
                          </div>
                        </div>
                        <div v-if="type === 'PARTY'" class="form_ttl">
                          자동연장
                        </div>
                        <div v-if="type === 'PARTY'" class="inline">
                          <div
                            class="option_btn"
                            :class="{ on: etc === null }"
                            @click="etcSwitch(null)"
                          >
                            <button>전체</button>
                          </div>
                          <div
                            class="option_btn"
                            :class="{ on: etc === 'Y' }"
                            @click="etcSwitch('Y')"
                          >
                            <button>연장파티</button>
                          </div>
                          <div
                            class="option_btn"
                            :class="{ on: etc === 'N' }"
                            @click="etcSwitch('N')"
                          >
                            <button>일반파티</button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-else class="form_li">
                      <div class="form_ttl">상태</div>
                      <div class="form_in">
                        <div class="inline">
                          <div
                            class="option_btn"
                            :class="{ on: all }"
                            @click="allSwitch"
                          >
                            <button>전체</button>
                          </div>
                          <div
                            v-for="(con, i) in conditions"
                            class="option_btn"
                            :class="{ on: con.value }"
                            @click="conSwitch(con)"
                            :key="i"
                          >
                            <button>{{ con.name }}</button>
                          </div>
                        </div>
                        <div v-if="type === 'PARTY'" class="form_ttl">
                          자동연장
                        </div>
                        <div v-if="type === 'PARTY'" class="inline">
                          <div
                            class="option_btn"
                            :class="{ on: etc === null }"
                            @click="etcSwitch(null)"
                          >
                            <button>전체</button>
                          </div>
                          <div
                            class="option_btn"
                            :class="{ on: etc === 'Y' }"
                            @click="etcSwitch('Y')"
                          >
                            <button>연장파티</button>
                          </div>
                          <div
                            class="option_btn"
                            :class="{ on: etc === 'N' }"
                            @click="etcSwitch('N')"
                          >
                            <button>일반파티</button>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  getCurrentInstance,
  toRefs,
  computed,
  onMounted,
  onUnmounted
} from 'vue'

const componentState = props => {
  const { proxy } = getCurrentInstance()
  const state = reactive({
    conditions: props.conditionsList,
    tagList: computed(() => state.conditions.filter(c => c.value)),
    etc: props.autoExtension,
    all: props.conditionsList.every(c => !c.value) ? true : false,
    windowWidth: window.innerWidth,
    open: props.conditionOpen
  })

  const onOff = () => {
    state.open = !state.open
    proxy.$emit('updateOnOff', state.open)
  }

  const etcSwitch = etc => {
    if (etc === 'Y' && state.etc === 'N') {
      state.etc = null
    } else if (etc === 'N' && state.etc === 'Y') {
      state.etc = null
    } else {
      state.etc = etc
    }
    proxy.$emit('updateAutoExtension', state.etc)
  }

  const allSwitch = () => {
    if (!state.all) {
      state.all = !state.all
      if (state.all) {
        state.conditions = state.conditions.map(c => ({ ...c, value: false }))
        proxy.$emit('update', state.conditions)
      }
    } else {
      if (state.conditions.every(c => !c.value)) {
        return
      }
    }
  }

  const conSwitch = con => {
    con.value = !con.value
    if (state.all) {
      state.all = !state.all
      state.conditions = state.conditions.reduce(
        (pv, cv) =>
          cv.autoExtension
            ? [...pv, { ...cv, value: true }]
            : [...pv, { ...cv }],
        []
      )
    } else {
      if (state.conditions.every(c => c.value)) {
        state.all = !state.all
        state.conditions = state.conditions.reduce(
          (pv, cv) => [...pv, { ...cv, value: false }],
          []
        )
        return proxy.$emit('update', state.conditions)
      }
      if (
        con.status &&
        state.conditions.filter(c => c.status).every(c => !c.value)
      )
        return (con.value = !con.value)
    }

    proxy.$emit('update', state.conditions)
  }

  onMounted(
    () => (window.onresize = () => (state.windowWidth = window.innerWidth))
  )
  onUnmounted(() => (window.onresize = () => {}))

  return {
    ...toRefs(state),
    allSwitch,
    conSwitch,
    onOff,
    etcSwitch
  }
}

export default {
  name: 'search-conditions',
  props: {
    conditionsList: {
      type: [Array],
      default: () => []
    },
    autoExtension: {
      default: null
    },
    type: {
      type: [String],
      default: () => ''
    },
    height: {
      type: [String],
      default: ''
    },
    conditionOpen: {
      type: [Boolean],
      default: false
    }
  },
  setup(props) {
    const state = componentState(props)
    return { ...state }
  }
}
</script>

<style scoped></style>
